import { v4 as uuidv4 } from 'uuid';
import { ParsedModifierGroup } from '../types/menu';
import appContainer from './container';
import { RootStore } from '../app/store';
import {
  InfoTransmissionMessage,
  messagingActions,
} from '../reducers/messagingSlice';
import { UPSELL_METRIC_VERSION } from '../constants';

interface IUpsellAttempted {
  upsell_id: string;
  upsell_intent: string;
  upsell_issued_timestamp: string;
  upsell_phrase: string;
  trigger_by: string;
  upsell_default_item: string;
  upsell_items: string[];
  upsell_decline_items: string[];
  parent_item: string;
}

export class Upsell {
  upsellAttempted: IUpsellAttempted | null = null;
  upsellSettled: boolean = false;
  getUpsellAttempted() {
    return this.upsellAttempted;
  }
  setUpsellAttempted(value: IUpsellAttempted | null) {
    this.upsellAttempted = value;
  }
  getUpsellSettled() {
    return this.upsellSettled;
  }
  setUpsellSettled(value: boolean) {
    this.upsellSettled = value;
  }
  attempt(
    constraint: ParsedModifierGroup,
    parentItem: string,
    triggerBy: string,
    prompt: string,
    upsellName: string
  ) {
    const {
      upsell_items = [],
      upsell_decline_items = [],
      upsell_default_item = '',
    } = constraint;
    const upsellAttempted = {
      upsell_id: uuidv4(),
      upsell_issued_timestamp: new Date().toISOString(),
      upsell_phrase: prompt,
      trigger_by: triggerBy,
      upsell_intent: upsellName,
      upsell_default_item,
      upsell_items,
      upsell_decline_items,
      parent_item: parentItem,
    };
    this.setUpsellAttempted(upsellAttempted);
    this.setUpsellSettled(false);
    return upsellAttempted;
  }
  reject(upsellRejectedItems?: string[]) {
    if (this.upsellAttempted) {
      const {
        upsell_id,
        upsell_issued_timestamp,
        upsell_intent,
        parent_item,
        upsell_default_item = '',
        upsell_phrase,
      } = this.upsellAttempted;
      this.setUpsellSettled(true);
      return {
        upsell_id,
        upsell_issued_timestamp,
        upsell_intent,
        parent_item,
        upsell_phrase,
        upsell_rejected_items: upsellRejectedItems
          ? upsellRejectedItems
          : [upsell_default_item],
        cx_utterance: '', // for data schema consistency as AI will send this field
        is_upsell_attempted: 'yes',
        is_upsell_accepted: 'no',
      };
    }
  }
  accept(upsellAcceptedItems: string[]) {
    if (this.upsellAttempted) {
      const {
        upsell_id,
        upsell_issued_timestamp,
        upsell_intent,
        parent_item,
        upsell_phrase,
      } = this.upsellAttempted;
      this.setUpsellSettled(true);
      return {
        upsell_id,
        upsell_issued_timestamp,
        upsell_intent,
        parent_item,
        upsell_phrase,
        upsell_accepted_items: upsellAcceptedItems,
        cx_utterance: '', // for data schema consistency as AI will send this field
        is_upsell_attempted: 'yes',
        is_upsell_accepted: 'yes',
      };
    }
  }

  settle() {
    if (this.upsellAttempted && !this.upsellSettled) {
      // Upsell is rejected implicitly
      const upsell_rejected = this.reject();
      const store = appContainer.get<RootStore>('store');
      const message = 'Auto reject upsell';
      const payload: Partial<InfoTransmissionMessage> = {
        data: {
          type: 'METRIC',
          message: message,
          metadata: {
            upsell_rejected,
            cx_utterance: '', // for data schema consistency as AI will send this field
            upsell_metric_version: UPSELL_METRIC_VERSION,
          },
        },
      };
      store?.dispatch(messagingActions.sendInfo(payload as any));
    }
    this.setUpsellAttempted(null);
  }
}

export const upsell = new Upsell();
