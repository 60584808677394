import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { infoSeqIdCounter } from '../app/middleware';
import { DUMMY_RESTAURANT_CODE, OUT_OF_SESSION } from '../constants';
import {
  AgentTypes,
  CheckResponseTypes,
  CouponResponseTypes,
  EventTypes,
} from '../constants/event';
import { AIStatusColors } from '../redux/features/ai/ai.constants';
import { IAIState } from '../redux/features/ai/ai.props';
import { ITaskRouterSlice } from '../redux/features/taskRouter/taskRouter.props';
import { selectIsWebSocketConnected } from '../selectors/message';
import { readEnvVariable } from '../utils';
import { agent } from '../utils/agent';
import { cyborgAlert } from '../utils/alert';
import { get } from '../utils/api';
import { AsrcPcmPlayer } from '../utils/asrcAudio';
import logger from '../utils/logger';
import { ILoyaltyTransmissionMessage } from './cartSlice.props';
import { selectMenuVersion } from './menuSlice';
import { filterBufferedEvents } from './messagingSlice.utils';
import { IOrderTransmissionMessage } from './orderSlice.props';
import { selectStage } from './restaurantSlice';
import { updateNonCriticalCheckScore } from '../redux/features/healthScore/healthScore.slice';
import { NonCriticalChecksNames } from '../redux/features/healthScore/healthScore.constants';

export enum SessionSource {
  hitl = 'HITL',
  communicator = 'COMMUNICATOR',
}

export enum RestaurantStaffInterventionStatus {
  initial = 'INITIAL',
  open = 'OPEN',
  close = 'CLOSE',
}

export interface TransmissionMessage {
  seq: number;
  id: string;
  event: EventTypes;
  timestamp: string;
  agent_id: string;
  agent_type: AgentTypes;
  session_id: string;
  metadata?: any;
  restaurant_code?: string;
}

export interface IMessageReceived {
  messages: TransmissionMessage[];
  taskRouter?: ITaskRouterSlice;
  isSessionBufferedMessages?: boolean;
  sessionBoundarySessionId?: string;
  ai?: IAIState;
}

export interface NewTransmissionMessage extends TransmissionMessage {
  data: {
    terminal_id: string;
    restaurant_code: string;
    session_id: string;
    media_format: string;
  };
}

export interface EndSessionTransmissionMessage extends TransmissionMessage {
  data: {
    restaurant_code: string;
    session_id: string;
  };
}

export interface AudioFrameTransmissionMessage extends TransmissionMessage {
  data: {
    payload: string;
    source_name: string;
  };
}

export interface TextFrameTransmissionMessage extends TransmissionMessage {
  data: {
    status: 'hypothesis' | 'final' | 'TTS';
    payload: string;
    metadata?: any;
    hitl_mute?: boolean;
  };
}

export interface EntityMenuItem {
  id: number; // the id of the menu item or modifier and HITL will handle the time based menu and availabilty
  name: string;
  is_custom_text: boolean;
  price: number;
  tax: number;
  available: boolean;
  quantity: number;
  pos_specific_properties: {
    pos_id: number;
    pos_name?: string;
    modcode?: string;
  };
  children: EntityMenuItem[] | [];
  modifier_group_id: string;
  options: EntityMenuItem[] | [];
  path_to_entity: string[] | [];
}

export interface Hypothesis {
  dialog: string[];
  responses: string[];
  customer_intent: 'order' | 'other';
  confidence: number;
  order_items: EntityMenuItem[];
  is_brand_menu: boolean;
}

export interface HypothesisTransmissionMessage extends TransmissionMessage {
  data: {
    hypotheses: Hypothesis[]; // will only have one hypothesis object in the hypotheses field for each session for now
  };
}

export interface InfoTransmissionMessage extends TransmissionMessage {
  data: {
    type: 'INFO' | 'METRIC' | EventTypes.hypothesis;
    message: string;
    code?: string;
    metadata?: any; // This is really a json type per the spec, but... shocked TS doesn't have this.
  };
}

export enum AgentInterceptionType {
  ORDER_CHANGED = 'ORDER_CHANGED',
  TYPING = 'TYPING',
  TTS_SENT = 'TTS_SENT',
  UNSUPERVISED_AI_ESCALATION = 'UNSUPERVISED_AI_ESCALATION',
  CANCEL_ORDER = 'CANCEL_ORDER',
  FINISH_ORDER = 'FINISH_ORDER',
}

export enum StaffInterceptionType {
  AGENT_REQUESTED_STAFF = 'AGENT_REQUESTED_STAFF',
}
export interface AgentInterceptionMessage extends TransmissionMessage {
  data: {
    type: AgentInterceptionType;
    description?: string;
  };
}

export interface StaffInterceptionMessage extends TransmissionMessage {
  data: {
    type: StaffInterceptionType;
    description?: string;
  };
}

export interface StaffInfoTransmissionMessage extends TransmissionMessage {
  data: {
    payload: string;
    locale: string;
  };
}

interface ICouponApplyStatus {
  id: string;
  code: string;
  applied: boolean;
}

export interface CheckTransmissionMessage extends TransmissionMessage {
  data: {
    check?: {
      total: string;
      subtotal: string;
      tax: string;
    };
    error?: string;
    context?: string;
    display?: string;
    coupon_apply_status?: ICouponApplyStatus[];
    notification_enabled: boolean;
    request: string;
    status: CheckResponseTypes;
    transaction_id: string;
    request_id: string;
    final: boolean;
  };
}

export interface ICheckCouponTransmissionMessage extends TransmissionMessage {
  data: {
    couponno?: string;
    coupontext?: string;
    error?: string;
    status: CouponResponseTypes;
    transaction_id: string;
    request_id?: string;
  };
}

export interface ErrorTransmissionMessage extends TransmissionMessage {
  data: {
    message: string;
  };
}

export interface StaffInterVentionTransmissionMessage
  extends TransmissionMessage {
  restaurant_code: string;
  data: {
    type: string;
  };
}

export interface IStatusTransmissionMessage extends TransmissionMessage {
  data: {
    status: {
      code: number;
      type: string;
      message: string;
      additional_context?: string;
    };
    pos_partner: string;
    datetime: string;
    request_id?: string;
    result?: {
      transaction_id: string;
    };
  };
}

export interface IHITLSessionStartTransmissionMessage
  extends TransmissionMessage {
  data: {
    reason: string;
  };
}

export interface IHITLSessionEndTransmissionMessage
  extends TransmissionMessage {
  data: {
    reason: string;
  };
}

export interface IAIStatusTransmissionMessage extends TransmissionMessage {
  data: {
    status: AIStatusColors;
    message: string;
  };
}

export interface IHITLEventConnectionTransmissionMessage
  extends TransmissionMessage {
  data: {
    message: string;
  };
}

export interface IHITLAgentFirstActivityTransmissionMessage
  extends TransmissionMessage {
  data: {
    message: string;
  };
}

export interface ICancelOrderTransmissionMessage extends TransmissionMessage {
  data: {
    check_id: string;
    source: string;
    store_id: string;
    order_id: string;
    request_id: string;
  };
}

export interface IAIEscalationTransmissionMessage extends TransmissionMessage {
  data: {
    hypotheses: Hypothesis[];
    number_of_turns_before_handoff: number;
    customer_requested_handoff: boolean;
    escalation_reason: string;
  };
}

export interface MessagingState {
  isEstablishingConnection: boolean;
  isConnected: boolean;
  isAudioWSConnected: boolean;
  isEstablishingAudioWSConnection: boolean;
  sentTextFrames: TextFrameTransmissionMessage[];
  inProgressHypothesisTextFrames: TextFrameTransmissionMessage[];
  sentInfo: Pick<InfoTransmissionMessage, 'data'>[];
  sentEnd: EndSessionTransmissionMessage[];
  sendOrder: IOrderTransmissionMessage[];
  sendLoyalty: ILoyaltyTransmissionMessage[];
  sendAgentFirstActivity: any[];
  sendAgentFirstInterception: any[];
  sendAgentInterception: any[];
  sendStaffInterception: any[];
  finalTextFrames: TextFrameTransmissionMessage[];
  startFrame: NewTransmissionMessage | null;
  hypothesisFrame: HypothesisTransmissionMessage | null;
  handledHypothesisFrames: Record<string, number[]>;
  currentSessionId: string;
  isRestaurantStaffJoined: Record<string, boolean>;
  isAIActive: boolean;
  isPlaying: boolean;
  asrcPlayer: AsrcPcmPlayer;
  isNewSession: boolean;
  isCarPresent: boolean;
  isStaffIntervention: boolean;
  isStaffIntervenedBeforeCarExit: boolean;
  sessionSource: SessionSource;
  isTTSOn: boolean;
  sentTTSRequest: TransmissionMessage[];
  sentError: ErrorTransmissionMessage[];
  restaurantStaffIntervention: RestaurantStaffInterventionStatus;
  sendHITLSessionStart: IHITLSessionStartTransmissionMessage[];
  sendHITLSessionEnd: IHITLSessionEndTransmissionMessage[];
  sendHITLEventConnection: IHITLEventConnectionTransmissionMessage[];
  sbFinalTextFrames: TextFrameTransmissionMessage[]; //Received via session buffer API regarding conversations happened before restaurant specific HITL page
  sbInProgressHypothesisTextFrames: TextFrameTransmissionMessage[]; //Received via session buffer API regarding conversations happened before restaurant specific HITL page
  sendCancelOrder: ICancelOrderTransmissionMessage[];
}

export interface BufferedAudioState {
  frames: AudioFrameTransmissionMessage[];
  indexLocations: { [seq: number]: number };
  bufferedSeqs: number[];
  lastBufferFlush: number;
}

export const initialState: MessagingState = {
  isEstablishingConnection: false,
  isConnected: false,
  isAudioWSConnected: false,
  isEstablishingAudioWSConnection: false,
  currentSessionId: `${uuidv4()}`,
  sentTextFrames: [],
  sentInfo: [],
  sentEnd: [],
  sendOrder: [],
  sendLoyalty: [],
  sendAgentFirstActivity: [],
  sendAgentFirstInterception: [],
  sendAgentInterception: [],
  sendStaffInterception: [],
  inProgressHypothesisTextFrames: [],
  finalTextFrames: [],
  startFrame: null,
  hypothesisFrame: null,
  handledHypothesisFrames: {},
  isRestaurantStaffJoined: {},
  isAIActive: false, // It's true when the restaurant has AI on. It is false when restaurant staff intervention happens.
  isPlaying: false,
  asrcPlayer: new AsrcPcmPlayer(),
  isNewSession: false,
  isCarPresent: false,
  isStaffIntervention: false,
  isStaffIntervenedBeforeCarExit: false,
  sessionSource: SessionSource.hitl,
  isTTSOn: false,
  sentTTSRequest: [],
  sentError: [],
  restaurantStaffIntervention: RestaurantStaffInterventionStatus.initial,
  sendHITLSessionStart: [],
  sendHITLSessionEnd: [],
  sendHITLEventConnection: [],
  sbFinalTextFrames: [],
  sbInProgressHypothesisTextFrames: [],
  sendCancelOrder: [],
};

interface IBufferMessagesResponseType {
  events: TransmissionMessage[];
}

export const getSessionBufferMessages = createAsyncThunk(
  'session/bufferMessages',
  async (
    {
      isCurrentTaskUnsupervised,
      timeout = 60000,
    }: { isCurrentTaskUnsupervised: boolean; timeout?: number },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      taskRouter,
      config: { SESSION_BUFFER_API, SESSION_BUFFER_API_AUTHENTICATION },
      ai,
    } = getState();

    const agent_types =
      AgentTypes.orderBoard +
      `${
        isCurrentTaskUnsupervised
          ? ',' + AgentTypes.llm + ',' + AgentTypes.menuModule
          : ''
      }`;

    Promise.race([
      get({
        url: `${SESSION_BUFFER_API}/sessions/${taskRouter.taskAssignedSessionId}/events?agent_type=${agent_types}`,
        headers: {
          Authorization: `Bearer ${SESSION_BUFFER_API_AUTHENTICATION}`,
        },
        successCallback: (response: { data: IBufferMessagesResponseType }) => {
          const receivedEvents = response?.data?.events || [];
          const bufferedMessages = isCurrentTaskUnsupervised
            ? filterBufferedEvents(receivedEvents)
            : receivedEvents;

          if (bufferedMessages.length) {
            logger.log({
              message:
                'Received & filtered out the following buffered messages',
              moreInfo: JSON.stringify(bufferedMessages),
            });
            dispatch(
              messagingActions.messageReceived({
                messages: bufferedMessages,
                isSessionBufferedMessages: true,
                taskRouter,
                ai,
              })
            );
          }
          dispatch(
            updateNonCriticalCheckScore({
              name: NonCriticalChecksNames.apiSessionBuffer,
              value: 1,
            })
          );
        },
        errorCallback: (error) => {
          dispatch(
            updateNonCriticalCheckScore({
              name: NonCriticalChecksNames.apiSessionBuffer,
              value: 0,
            })
          );
          logger.error({
            message: 'Failed to get buffered messages',
            error,
          });
          return rejectWithValue(error);
        },
      }),
      new Promise((resolve) => {
        dispatch(
          updateNonCriticalCheckScore({
            name: NonCriticalChecksNames.apiSessionBuffer,
            value: 0,
          })
        );
        setTimeout(
          resolve,
          timeout,
          new Error(
            'Timeout to get buffered events messages from Session Buffer API'
          )
        );
      }),
    ]).then((res) => {
      if (res instanceof Error) {
        logger.error(res);
      }
    });
  }
);

type BaseNetworkCallLogsData = {
  url: string;
  message: string;
  /** time in milliseconds */
  duration: number;
};

type NetworkCallLogsData = BaseNetworkCallLogsData &
  (
    | {
        /** network calls made via which api */
        via: 'websocket' | 'fetch' | 'axios' | 'axios_api_wrapper';
      }
    | {
        /** graphql operation */
        graphQLOperation: string;
        /** network calls made via which api */
        via: 'graphql_sdk';
      }
  );

export const sendNetworkCallLogs = createAsyncThunk(
  'messaging/sendNetworkCallLogs',
  async (
    { message, ...metadata }: NetworkCallLogsData,
    { getState, dispatch }
  ) => {
    const { config, sessionBoundary, messages, restaurant, taskRouter } =
      getState();
    const isWebSocketConnected = selectIsWebSocketConnected(getState());

    const infoData: Pick<InfoTransmissionMessage, 'data'> = {
      data: { type: 'METRIC', message, metadata },
    };

    if (isWebSocketConnected) {
      dispatch(messagingActions.sendInfo(infoData));
      return;
    }

    // send event via event backend's REST API
    const infoEvent: InfoTransmissionMessage = {
      id: uuidv4(),
      seq: infoSeqIdCounter.increment(),
      event: EventTypes.info,
      session_id:
        taskRouter?.taskAssignedSessionId ||
        sessionBoundary?.sessionId ||
        messages?.currentSessionId,
      agent_type: AgentTypes.HITL,
      agent_id: agent.getAgentId(),
      timestamp: new Date().toISOString(),
      metadata: {
        environment: readEnvVariable('DEPLOY_ENV') || 'unknown',
      },
      restaurant_code:
        restaurant.selectedRestaurantDetails?.restaurantCode ||
        DUMMY_RESTAURANT_CODE,
      ...infoData,
    };

    const url = `${config.EVENT_API}/v1/event`;

    await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${config.EVENT_API_BASIC_AUTH_PARAM}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(infoEvent),
    }).catch((err) => {
      logger.error(err);
    });
  }
);

export const sendTestSignal = createAsyncThunk(
  'messaging/sendTestSignal',
  async (undefined, { getState, dispatch }) => {
    const {
      config,
      sessionBoundary: { sessionId },
      messages: { currentSessionId },
    } = getState();

    // send event via event backend's REST API
    const infoEvent: InfoTransmissionMessage = {
      id: uuidv4(),
      seq: infoSeqIdCounter.increment(),
      event: EventTypes.info,
      session_id: sessionId || currentSessionId || 'dummy_session_id',
      agent_type: AgentTypes.HITL,
      agent_id: agent.getAgentId(),
      timestamp: new Date().toISOString(),
      metadata: {
        environment: readEnvVariable('DEPLOY_ENV') || 'unknown',
      },
      data: {
        type: 'METRIC',
        message: 'test rest api signal from hitl',
      },
    };

    const url = `${config.EVENT_API}/v1/event`;

    await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${config.EVENT_API_BASIC_AUTH_PARAM}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(infoEvent),
    }).catch((err) => {
      logger.error(err);
    });
  }
);

export const messagingSlice = createSlice({
  name: 'messaging',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    startConnecting: (state) => {
      state.isConnected = false;
      state.isEstablishingConnection = true;
    },
    connectionEstablished: (state) => {
      state.isConnected = true;
      state.isEstablishingConnection = false;
    },
    connectionLost: (state) => {
      state.isConnected = false;
      state.isEstablishingConnection = true;
    },
    closeConnection: (state) => {
      state.isConnected = false;
      state.isEstablishingConnection = false;
    },
    startAudioWSConnecting: (state) => {
      state.isAudioWSConnected = false;
      state.isEstablishingAudioWSConnection = true;
    },
    audioWSConnectionEstablished: (state) => {
      state.isAudioWSConnected = true;
      state.isEstablishingAudioWSConnection = false;
    },
    audioWSConnectionLost: (state) => {
      state.isAudioWSConnected = false;
      state.isEstablishingAudioWSConnection = true;
    },
    closeAudioWSConnection: (state) => {
      state.isAudioWSConnected = false;
      state.isEstablishingAudioWSConnection = false;
    },
    messageReceived: (state, action: PayloadAction<IMessageReceived>) => {
      const {
        messages = [],
        taskRouter,
        isSessionBufferedMessages,
      } = action.payload;
      messages.forEach((message) => {
        if (
          [
            EventTypes.check,
            EventTypes.orderStatus,
            EventTypes.loyaltyStatus,
            EventTypes.connect,
            EventTypes.disconnect,
            EventTypes.aiEscalation,
            EventTypes.order,
          ].includes(message.event)
        ) {
          //These messages are handled in other slices
          return;
        }

        if (
          taskRouter?.currentTaskId &&
          taskRouter?.taskAssignedSessionId !== message.session_id
        ) {
          //If the current task is from task-router and if the message session is not matching with
          //task-router current task session, don't process the received message - exception is TTS ON/OFF messages
          if (![EventTypes.TTSOff, EventTypes.TTSOn].includes(message.event))
            return;
        }

        if (
          message.session_id !== OUT_OF_SESSION &&
          state.currentSessionId !== message.session_id
        ) {
          state.currentSessionId = message.session_id;
          state.sessionSource = SessionSource.communicator;
        }
        switch (message.event) {
          case EventTypes.start:
            // TODO handle new start frame
            state.startFrame = message as NewTransmissionMessage;
            break;
          case EventTypes.audio:
            if (!state.isPlaying) {
              return;
            }
            const audio = message as AudioFrameTransmissionMessage;
            if (
              audio.data.source_name === 'RESTAURANTSTAFF' &&
              message.session_id &&
              state.isRestaurantStaffJoined[message.session_id] === undefined
            ) {
              state.isRestaurantStaffJoined[message.session_id] = true;
            }

            if (audio.data.source_name !== 'ORDERBOARD') {
              // TODO Ignore non-guest audio
              // Rendering multiple audio streams is going to be a challenge.
              // But we can defer it.
              logger.error('Audio data source is not ORDERBOARD');
              break;
            }

            if (state.asrcPlayer.audioContext) {
              state.asrcPlayer.pushBase64(audio.data.payload);
            }

            break;
          case EventTypes.text:
            const textEvent = message as TextFrameTransmissionMessage;
            // TODO identify why we're getting an echo
            if (state.sentTextFrames.map((f) => f.id).includes(textEvent.id)) {
              break;
            }
            if (isSessionBufferedMessages) {
              if (textEvent.data.status === 'hypothesis') {
                state.sbInProgressHypothesisTextFrames.push(textEvent);
              } else if (textEvent.data.status === 'TTS') {
                state.sbFinalTextFrames.push(textEvent);
                const textPayload = textEvent.data.payload;
                if (!textEvent.data.hitl_mute && textPayload) {
                  cyborgAlert(textPayload);
                }
              } else {
                state.sbFinalTextFrames.push(textEvent);
                // Clear out all "in progress" text frames
                state.sbInProgressHypothesisTextFrames.length = 0;
              }
              break;
            }

            // TODO check seq id is larger than last
            if (textEvent.data.status === 'hypothesis') {
              state.inProgressHypothesisTextFrames.push(textEvent);
            } else if (textEvent.data.status === 'TTS') {
              state.finalTextFrames.push(textEvent);
              const textPayload = textEvent.data.payload;
              if (!textEvent.data.hitl_mute && textPayload) {
                cyborgAlert(textPayload);
              }
            } else {
              state.finalTextFrames.push(textEvent);
              // Clear out all "in progress" text frames
              state.inProgressHypothesisTextFrames.length = 0;
            }
            break;
          case EventTypes.hypothesis:
            const hypothesisFrame = message as HypothesisTransmissionMessage;
            if (state.currentSessionId === hypothesisFrame.session_id) {
              state.hypothesisFrame = message as HypothesisTransmissionMessage;
            }
            break;
          case EventTypes.startSession:
            state.isNewSession = true;
            state.isStaffIntervention = false;
            state.restaurantStaffIntervention =
              RestaurantStaffInterventionStatus.initial;
            state.isStaffIntervenedBeforeCarExit = false;
            break;
          case EventTypes.carEnter: {
            state.isNewSession = true;
            state.isCarPresent = true;
            state.restaurantStaffIntervention =
              RestaurantStaffInterventionStatus.initial;
            if (!isSessionBufferedMessages) {
              state.inProgressHypothesisTextFrames = [];
              state.finalTextFrames = [];
            }
            state.isStaffIntervenedBeforeCarExit = false;
            break;
          }
          case EventTypes.carExit:
            state.isNewSession = false;
            state.isCarPresent = false;
            state.isStaffIntervenedBeforeCarExit = state.isStaffIntervention;
            state.isStaffIntervention = false;
            break;
          case EventTypes.info:
            if (
              (message as StaffInfoTransmissionMessage).data.payload ===
              'StaffInterVention'
            ) {
              state.isStaffIntervention = true;
              state.isAIActive = false;
              state.hypothesisFrame = null;
              break;
            }
            break;
          case EventTypes.TTSOff:
            state.isTTSOn = false;
            break;
          case EventTypes.TTSOn:
            state.isTTSOn = true;
            break;
          case EventTypes.aiStatus:
            state.isAIActive = true;
            break;
          case EventTypes.staffIntervention:
            state.isStaffIntervention = true;
            state.isAIActive = false;
            state.hypothesisFrame = null;
            break;
          default:
            logger.error({
              message: `Received an unhandled message event with type ${message.event}`,
            });
        }
      });
    },
    sendMessage: (
      state,
      action: PayloadAction<TextFrameTransmissionMessage>
    ) => {
      state.sentTextFrames.push(action.payload);
    },
    sendInfo: (
      state,
      action: PayloadAction<Pick<InfoTransmissionMessage, 'data'>>
    ) => {
      state.sentInfo.push(action.payload);
    },
    sendAgentFirstActivity: (
      state,
      action: PayloadAction<IHITLAgentFirstActivityTransmissionMessage>
    ) => {
      state.sendAgentFirstActivity.push(action.payload);
    },
    sendAgentFirstInterception: (
      state,
      action: PayloadAction<Pick<AgentInterceptionMessage, 'data'>>
    ) => {
      state.sendAgentFirstInterception.push(action.payload);
    },
    sendAgentInterception: (
      state,
      action: PayloadAction<Pick<AgentInterceptionMessage, 'data'>>
    ) => {
      state.sendAgentInterception.push(action.payload);
    },
    sendStaffInterception: (
      state,
      action: PayloadAction<Pick<StaffInterceptionMessage, 'data'>>
    ) => {
      state.sendStaffInterception.push(action.payload);
    },
    handleHypothesisFrame: (
      state,
      action: PayloadAction<{ sessionId: string; index: number }>
    ) => {
      // the order item will be pushed to hypothesis array in sequence so keep the idex of order item already handled to reduce duplicate rendering
      state.handledHypothesisFrames[action.payload.sessionId].push(
        action.payload.index
      );
    },
    sendEndSession: (
      state,
      action: PayloadAction<EndSessionTransmissionMessage>
    ) => {
      state.sentEnd.push(action.payload);
    },
    sendOrder: (state, action: PayloadAction<IOrderTransmissionMessage>) => {
      state.sendOrder.push(action.payload);
    },
    sendLoyalty: (
      state,
      action: PayloadAction<ILoyaltyTransmissionMessage>
    ) => {
      state.sendLoyalty.push(action.payload);
    },
    closeRestaurantStaffJoined: (
      state,
      action: PayloadAction<{ sessionId: string | null }>
    ) => {
      if (state.isRestaurantStaffJoined && action.payload.sessionId) {
        state.isRestaurantStaffJoined[action.payload.sessionId] = false;
      }
    },
    clearMessages: (state) => {
      state.finalTextFrames = [];
      state.inProgressHypothesisTextFrames = [];
      state.sbFinalTextFrames = [];
      state.sbInProgressHypothesisTextFrames = [];
      state.sentTextFrames = [];
      state.sendAgentFirstActivity = [];
      state.sendAgentFirstInterception = [];
      state.sendAgentInterception = [];
      state.sendStaffInterception = [];
    },
    setIsPlaying: (state, action: PayloadAction<boolean>) => {
      state.isPlaying = action.payload;
      if (state.isPlaying) {
        state.asrcPlayer.startAudio();
      } else {
        state.asrcPlayer.aclose();
      }
    },
    setIsAIActive: (state, action: PayloadAction<boolean>) => {
      state.isAIActive = action.payload; // isAIActive is reset by getAIControlPanelStatus when restaurant is selected and when hitl session ends
    },
    setRestaurantStaffIntervention: (
      state,
      action: PayloadAction<RestaurantStaffInterventionStatus>
    ) => {
      state.restaurantStaffIntervention = action.payload;
    },
    resetCarState: (state) => {
      state.isNewSession = false;
      state.isCarPresent = false;
      state.restaurantStaffIntervention =
        RestaurantStaffInterventionStatus.initial;
      state.isStaffIntervention = false;
    },
    setStaffIntervention: (state, action: PayloadAction<boolean>) => {
      state.isStaffIntervention = action.payload;
    },
    sendTTSRequest: (state, action: PayloadAction<TransmissionMessage>) => {
      state.sentTTSRequest.push(action.payload);
    },
    sendError: (state, action: PayloadAction<ErrorTransmissionMessage>) => {
      state.sentError.push(action.payload);
    },
    resetHypothesisFrame: (state) => {
      state.hypothesisFrame = null;
    },
    carEnter: (state) => {
      state.isNewSession = true;
      state.isCarPresent = true;
      state.restaurantStaffIntervention =
        RestaurantStaffInterventionStatus.initial;
    },
    sendHITLSessionStart: (
      state,
      action: PayloadAction<IHITLSessionStartTransmissionMessage>
    ) => {
      state.sendHITLSessionStart.push(action.payload);
    },
    sendHITLSessionEnd: (
      state,
      action: PayloadAction<IHITLSessionEndTransmissionMessage>
    ) => {
      state.sendHITLSessionEnd.push(action.payload);
    },
    sendHITLEventConnection: (
      state,
      action: PayloadAction<IHITLEventConnectionTransmissionMessage>
    ) => {
      state.sendHITLEventConnection.push(action.payload);
    },
    sendCancelOrder: (
      state,
      action: PayloadAction<ICancelOrderTransmissionMessage>
    ) => {
      state.sendCancelOrder.push(action.payload);
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(selectStage.fulfilled, selectMenuVersion.fulfilled),
      (state, action) => {
        state.hypothesisFrame = null;
      }
    );
  },
});

export const messagingActions = messagingSlice.actions;
export const {
  setIsAIActive,
  setStaffIntervention,
  resetHypothesisFrame,
  sendOrder,
  sendLoyalty,
  sendHITLSessionStart,
  sendHITLSessionEnd,
  startAudioWSConnecting,
  audioWSConnectionEstablished,
  audioWSConnectionLost,
  closeAudioWSConnection,
  sendCancelOrder,
  clearMessages,
} = messagingSlice.actions;

export default messagingSlice.reducer;
