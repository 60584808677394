import {
  InfoTransmissionMessage,
  messagingActions,
} from '../../reducers/messagingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ParsedMenuItem, ParsedModifierGroup } from '../../types/menu';
import { upsell } from '../../utils/upsell';
import { selectIsAIAutoMode } from '../../redux/features/ai/ai.selector';
import { UPSELL_METRIC_VERSION } from '../../constants';

const useSendModifierSelectionInfo = () => {
  const dispatch = useDispatch();
  const isAIAutoMode = useSelector(selectIsAIAutoMode);
  const sendModifierSelectionInfo = ({
    menuItem,
    modGroup,
    selected,
  }: {
    menuItem: ParsedMenuItem;
    modGroup: ParsedModifierGroup;
    selected: boolean;
  }) => {
    const message =
      'Set ' +
      selected +
      ' for ' +
      modGroup.name +
      ' modifier for menu item ' +
      menuItem.name;
    const {
      id,
      name,
      isUpsell: is_upsell,
      isUpsellPrompt: is_upsell_prompt,
      label,
    } = menuItem;
    const {
      id: modGroupId,
      name: modGroupName,
      isUpsell: modGroupIsUpsell,
      is_upsell_prompt: modGroupIsUpsellPrompt,
      label: modGroupLabel,
    } = modGroup;

    // Upsell is calculated
    const upsellAttempted = upsell.getUpsellAttempted();
    let upsell_rejected;
    let upsell_accepted;
    let upsell_metric_version;
    if (upsellAttempted) {
      const { upsell_decline_items, upsell_items } = upsellAttempted;
      if (upsell_decline_items.includes(menuItem.name)) {
        upsell_rejected = upsell.reject([menuItem.name]);
      } else if (upsell_items.includes(menuItem.name)) {
        upsell_accepted = upsell.accept([menuItem.name]);
      }
      upsell_metric_version = UPSELL_METRIC_VERSION;
    }
    const payload: Partial<InfoTransmissionMessage> = {
      data: {
        type: 'METRIC',
        message: message,
        metadata: {
          menuItem: {
            id,
            name,
            ...(is_upsell !== undefined && { is_upsell }),
            ...(is_upsell_prompt !== undefined && { is_upsell_prompt }),
            ...(label && { label }),
          },
          modGroup: {
            id: modGroupId,
            name: modGroupName,
            ...(modGroupIsUpsell !== undefined && {
              is_upsell: modGroupIsUpsell,
            }),
            ...(modGroupIsUpsellPrompt !== undefined && {
              is_upsell_prompt: modGroupIsUpsellPrompt,
            }),
            ...(modGroupLabel && { label: modGroupLabel }),
          },
          upsell_metric_version,
          upsell_rejected,
          upsell_accepted,
        },
      },
    };
    if (!isAIAutoMode) dispatch(messagingActions.sendInfo(payload as any));
  };
  return {
    sendModifierSelectionInfo,
  };
};

export default useSendModifierSelectionInfo;
