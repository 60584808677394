import { AutocompleteRenderOptionState, Chip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import moment from 'moment';
import { useAppSelector, useShallowSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { getModSymbolMapping } from '../../selectors/menu';
import { getHightlight, getQuantityFromInput } from '../../utils/autocomplete';
import Colors from '../../utils/color';
import { TopLevelMenuItem, isItem86edToday } from '../../utils/menu';
import { ParsedMenuItem } from '../../types/menu';
import { selectActiveRestaurantTimezone } from '../../selectors/restaurant';

const useStyles = makeStyles(() => ({
  modifier: {
    backgroundColor: '#ab51ff',
    color: 'white',
    borderRadius: '10px',
    fontSize: '.75rem',
    padding: '0 7px',
  },
  highlightText: {
    marginRight: '15px',
    color: (props: any) =>
      props.isUnavailable ? Colors.red : props.isModifier && '#ab51ff',
    textDecoration: (props: any) => props.isUnavailable && 'line-through',
  },
  unavailable: {
    backgroundColor: `${Colors.red} !important`,
    height: '18px !important',
    marginLeft: '10px',
    '& .MuiChip-label': {
      padding: '0 7px',
      fontSize: '.75rem',
      color: 'white',
    },
  },
}));

interface IAutocompleteOption {
  htmlElemProps: React.HTMLAttributes<HTMLLIElement>;
  menuItem: ParsedMenuItem | TopLevelMenuItem;
  state: AutocompleteRenderOptionState;
  handleOutOfOrderSearch: boolean;
}
export const AutocompleteOption = ({
  htmlElemProps,
  menuItem,
  state,
  handleOutOfOrderSearch,
}: IAutocompleteOption) => {
  const timezone = useAppSelector(selectActiveRestaurantTimezone);
  const tzAbbreviation = moment.tz(timezone).format('z');
  const isModifier = menuItem.category === 'modifier';
  const is86edToday = isItem86edToday(menuItem);
  const { inactive, activeTimePeriod } = menuItem as TopLevelMenuItem;
  const isUnavailable = is86edToday || inactive;
  const styleProps = { isModifier, isUnavailable };
  const classes = useStyles(styleProps);
  const cart = useShallowSelector((state: RootState) => state.cart);
  const selectedItem =
    useAppSelector((state) => state.dialog.selectedItem) ||
    Object.values(cart.cartItems)[Object.values(cart.cartItems).length - 1];
  const modSymbolMapping = useShallowSelector(getModSymbolMapping);

  const { quantity, inputValue } = getQuantityFromInput(
    state.inputValue,
    modSymbolMapping
  );
  const highlight = getHightlight(
    handleOutOfOrderSearch && inputValue.split(' ').length > 1
      ? inputValue.split(' ')[1]
      : inputValue,
    menuItem,
    quantity
  );

  let modifierTag = '';
  if (isModifier && selectedItem) {
    const modifier = menuItem as ParsedMenuItem;
    modifierTag =
      modifier.parentModifierGroupDisplayName ||
      modifier.parentModifierGroupName ||
      selectedItem.name;
  }

  return (
    <li
      data-testid="omnibar-autocomplete-option"
      {...htmlElemProps}
      key={menuItem.id}
      style={{
        pointerEvents: 'none',
      }}
    >
      <div>
        <span
          dangerouslySetInnerHTML={{ __html: highlight }}
          className={classes.highlightText}
        />
        {isModifier ? (
          <span className={classes.modifier}>&gt; {modifierTag}</span>
        ) : (
          <Typography
            color={isUnavailable ? Colors.red : undefined}
            fontSize=".75rem"
            component="span"
          >
            {menuItem.category}
          </Typography>
        )}
        {(is86edToday || (inactive && activeTimePeriod)) && (
          <Chip
            label={
              is86edToday
                ? "86'd"
                : `Inactive ${activeTimePeriod?.start} - ${activeTimePeriod?.end} ${tzAbbreviation}`
            }
            size="small"
            className={classes.unavailable}
          />
        )}
      </div>
    </li>
  );
};
