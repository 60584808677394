import {
  convertHypothesisItems,
  getCartItemProperties,
} from '../../utils/cart';
import {
  messagingActions,
  TextFrameTransmissionMessage,
} from '../../reducers/messagingSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  selectCartItems,
  selectCartItemsQuantity,
  selectHypothesisCartItems,
  selectPOSCartItems,
} from '../../selectors/cart';
import { selectFeatureFlagAutoCombo } from '../../redux/features/config/config.selector';
import {
  selectAutoComboCartItems,
  selectAutoComboCartItemsQuantity,
} from '../../redux/features/autoCombo/autoCombo.selector';
import { useSelector } from 'react-redux';
import { selectActiveRestaurantDetails } from '../../selectors/restaurant';
import { getMenuUnificationFeatureFlag } from '../../utils/session-storage';
import { ISendItemProperties } from '../../reducers/orderSlice.props';

const useSendMessageHooks = () => {
  const dispatch = useAppDispatch();
  const { restaurantCode } = useSelector(selectActiveRestaurantDetails);
  const cartItemsQuantity = useAppSelector(selectCartItemsQuantity);
  const cartItems = useAppSelector(selectCartItems);
  const hypothesisCartItems = useAppSelector(selectHypothesisCartItems);

  const autoComboEnabled = useAppSelector(selectFeatureFlagAutoCombo);
  const autoComboCartItemsQuantity = useAppSelector(
    selectAutoComboCartItemsQuantity
  );
  const autoComboCartItems = useAppSelector(selectAutoComboCartItems);

  const posCartItems = useAppSelector(selectPOSCartItems);

  const sendMessage = (message: string, metadata?: any) => {
    const payload: Partial<TextFrameTransmissionMessage> = {
      data: { payload: message, status: 'final', metadata },
    };
    dispatch(messagingActions.sendMessage(payload as any));
  };
  const sendReadBackMessage = (intent: string, cTimer: number) => {
    // Send the order items as metadata for dt-bridge to always have the latest cart state even if it's not completed or canceled
    let orderItems: ISendItemProperties[] = [];
    if (getMenuUnificationFeatureFlag() && posCartItems.length) {
      orderItems = posCartItems;
    } else {
      const autoComboItemPopulated =
        autoComboEnabled && Object.values(autoComboCartItems).length;
      const cartItemToUse = autoComboItemPopulated
        ? autoComboCartItems
        : cartItems;
      const cartItemsQuantityToUse = autoComboItemPopulated
        ? autoComboCartItemsQuantity
        : cartItemsQuantity;
      orderItems = Object.values(cartItemToUse).map((item) => {
        let groupId: string[] = [];

        const cartItem = getCartItemProperties(
          item,
          groupId,
          [],
          restaurantCode || '',
          cartItemsQuantityToUse
        );
        return cartItem;
      });
    }

    if (Object.values(hypothesisCartItems).length) {
      orderItems = [
        ...orderItems,
        ...convertHypothesisItems(Object.values(hypothesisCartItems)),
      ];
    }
    sendMessage('', { intent, timeDelta: cTimer, orderItems });
  };

  return {
    sendMessage,
    sendReadBackMessage,
  };
};
export default useSendMessageHooks;
