import { ErrorRounded } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import { Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';
import { IAIStatus } from '../../redux/features/taskRouter/taskRouter.props';
import Colors from '../../utils/color';
import theme from '../../utils/theme';
import soundIcon from '../../images/sound_diagram.svg';
import { Image } from '../Layout/Image';

export interface ILoadingIndicatorProps {
  status?: string;
}

const useStyles = makeStyles<Theme, ILoadingIndicatorProps>(({ spacing }) => ({
  loadingIndicatorClass: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  loadingBoxClass: {
    display: 'flex',
    width: '100%',
    height: spacing(40),
    alignItems: 'center',
    flexDirection: 'column',
  },
  restaurantNameClass: {
    height: spacing(4),
  },
  aiEscalationClass: {
    marginTop: theme.spacing(3),
    height: spacing(10),
    display: 'flex',
    alignItems: 'center',
    borderRadius: '12px',
    padding: spacing(3),
    gap: spacing(2),
    background: ({ status }) =>
      status === 'yellow'
        ? Colors.beeswax
        : status === 'red'
        ? Colors.apricot
        : status === 'green'
        ? '#30D158'
        : Colors.white,
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);',
  },
  aiEscalationHeadingClass: {
    fontWeight: '500 !important',
    paddingBottom: spacing(0.5),
  },
  aiSpanishClass: {
    display: 'flex',
    background: '#DDEEFF',
    alignItems: 'center',
    borderRadius: '12px',
    marginTop: spacing(2),
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    gap: spacing(1),
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);',
  },
}));

type LoadingIndicatorProps = {
  restaurantName: string;
  isFetchingTaskStatus: boolean;
  aiStatus?: IAIStatus;
};

const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  restaurantName,
  isFetchingTaskStatus,
  aiStatus,
}) => {
  const {
    loadingIndicatorClass,
    loadingBoxClass,
    restaurantNameClass,
    aiEscalationClass,
    aiEscalationHeadingClass,
    aiSpanishClass,
  } = useStyles({ status: aiStatus?.status });

  const showAIStatusIcon = () => {
    switch (aiStatus?.status) {
      case 'yellow':
        return <ErrorRounded fontSize="large" sx={{ color: Colors.amber }} />;
      case 'red':
        return (
          <WarningIcon fontSize="large" sx={{ color: Colors.guardsmanRed }} />
        );
      case 'green':
        return <InfoIcon fontSize="large" sx={{ color: Colors.white }} />;
      default:
        return null;
    }
  };

  return (
    <div data-testid="loading-indicator" className={loadingIndicatorClass}>
      <Box className={loadingBoxClass}>
        <Typography className={restaurantNameClass} variant="h5" gutterBottom>
          {!isFetchingTaskStatus ? restaurantName : ''}
        </Typography>
        <CircularProgress />
        <Typography variant="h4" gutterBottom>
          {isFetchingTaskStatus
            ? 'Task Assignment in Progress'
            : 'Switching restaurant...'}
        </Typography>
        <Typography>Please wait, this might take a moment</Typography>
        {aiStatus?.is_spanish_customer ? (
          <div className={aiSpanishClass}>
            <Image url={soundIcon} alt="Customer speaking sound" />
            <Typography>Spanish Speaking Customer</Typography>
          </div>
        ) : null}
        {aiStatus ? (
          <div className={aiEscalationClass}>
            {showAIStatusIcon()}
            <div>
              <Typography className={aiEscalationHeadingClass}>
                AI Status alert
              </Typography>
              <Typography>{aiStatus.message}</Typography>
            </div>
          </div>
        ) : null}
      </Box>
    </div>
  );
};

export default LoadingIndicator;
