class CheckEnterClicked {
  #isEnterClicked = false;

  getIsEnterClicked() {
    return this.#isEnterClicked;
  }

  setIsEnterClicked(newValue: boolean) {
    this.#isEnterClicked = newValue;
  }
}

export const checkEnterClicked = new CheckEnterClicked();

export enum UpsellTriggers {
  hitlOmnibar = 'hitl_omnibar',
}
