import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { TranscriptTypes } from '../../constants/enums';
import Colors from '../../utils/color';

type TranscriptMessageStyleProps = {
  type: TranscriptTypes;
};

const useStyles = makeStyles<Theme, TranscriptMessageStyleProps>(({}) => ({
  transcriptMessageClass: {
    fontSize: '14px',
    lineHeight: '20px',
    paddingBottom: '4px',
    display: 'flex',
    color: ({ type }) =>
      `${type === TranscriptTypes.AI ? '#1E1E1E' : Colors.guardsmanRed}`,
  },
  sourceClass: {
    width: '1.75rem',
    fontWeight: 'bold',
  },
  contentClass: {
    width: 'calc(100% - 3.75rem)',
  },
  langIndicatorClass: {
    width: '2rem',
    textAlign: 'center',
    paddingRight: '4px',
  },
  secondLangIndicatorClass: {
    backgroundColor: '#0797EA',
    color: '#FFFFFF',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  secondLangClass: {
    fontStyle: 'italic',
  },
}));

interface ITranscriptMessage {
  text: string;
  type: TranscriptTypes;
  secondLangText?: string;
}

export const TranscriptMessage = ({
  text = '•',
  type,
  secondLangText = '',
}: ITranscriptMessage) => {
  const {
    transcriptMessageClass,
    sourceClass,
    contentClass,
    langIndicatorClass,
    secondLangClass,
    secondLangIndicatorClass,
  } = useStyles({
    type,
  });

  return (
    <div className={transcriptMessageClass}>
      <div className={langIndicatorClass}>
        <div className={secondLangIndicatorClass}>
          {secondLangText ? 'S' : ''}
        </div>
      </div>
      <div className={sourceClass}>{type}:&nbsp;</div>
      <div className={contentClass}>
        <>
          {text}
          {secondLangText ? (
            <span className={secondLangClass}>
              {`     | ${secondLangText}`}
            </span>
          ) : null}
        </>
      </div>
    </div>
  );
};
