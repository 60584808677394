import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { EventSources, EventTypes } from '../constants/event';
import { PosProperties } from '../generated-interfaces/graphql';
import { CartItem, selectModifierInCartItem } from '../utils/cart';
import { ModSymbolCodeNameMappingType } from '../utils/mappings';
import { GenericMap } from '../utils/types';
import { LOYALTY_APPLY_STATUS } from './cartSlice.constants';
import {
  IEntityCartItem,
  ILoyaltyTransmissionMessage,
  initialCartState,
  LoyaltyCouponItem,
} from './cartSlice.props';
import {
  processCouponResponses,
  processLoyaltyResponses,
} from './cartSlice.utils';
import { selectMenuVersion } from './menuSlice';
import {
  ICheckCouponTransmissionMessage,
  IMessageReceived,
  IStatusTransmissionMessage,
  messagingActions,
  sendLoyalty as sendLoyaltyMessage,
  TransmissionMessage,
} from './messagingSlice';
import { orderActions } from './orderSlice';
import { extractOrderId } from './orderSlice.utils';
import { selectRestaurant, selectStage } from './restaurantSlice';
import logger from '../utils/logger';
import { ISendItemProperties } from './orderSlice.props';

export const sendOrderMetrics = createAsyncThunk(
  'cart/sendOrderMetrics',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const {
      cart,
      restaurant: { selectedRestaurantDetails },
      messages: { startFrame },
    } = getState();

    let restaurantCode = selectedRestaurantDetails.restaurantCode || undefined;

    if (!restaurantCode) {
      restaurantCode = startFrame?.data.restaurant_code;
      if (!restaurantCode) {
        throw rejectWithValue('No Current restaurantCode selected!');
      }
    }

    const cartItems = Object.values(cart.cartItems);
    let smallCart: {
      name: string;
      id: string;
      is_upsell?: boolean;
      is_upsell_prompt?: boolean;
      label?: string;
      children: { name: string; id: string }[];
    }[] = [];
    cartItems.forEach((item) => {
      let {
        name,
        id,
        childModifierGroups,
        isUpsell,
        isUpsellPrompt,
        label: parentLabel,
      } = item;
      let groups = Object.values(childModifierGroups);
      let children: {
        name: string;
        id: string;
        is_upsell?: boolean;
        is_upsell_prompt?: boolean;
        label?: string;
      }[] = [];
      groups.forEach((g) => {
        Object.values(g.selectedItems).forEach((child) => {
          let {
            name,
            id,
            isUpsell: is_upsell,
            isUpsellPrompt: is_upsell_prompt,
            label,
          } = child;
          children.push({
            name,
            id,
            ...(is_upsell !== undefined && { is_upsell }),
            ...(is_upsell_prompt !== undefined && { is_upsell_prompt }),
            ...(label && { label }),
          });
        });
      });
      smallCart.push({
        name,
        id,
        children,
        ...(isUpsell !== undefined && { is_upsell: isUpsell }),
        ...(isUpsellPrompt !== undefined && {
          is_upsell_prompt: isUpsellPrompt,
        }),
        ...(parentLabel && { label: parentLabel }),
      });
    });

    const orderMetrics = {
      store_id: restaurantCode,
      items: smallCart,
      source: EventSources.prestoVoice,
      request_id: uuidv4(),
      // session_id: sessionId,
    };

    const payload: Partial<TransmissionMessage & { data: { payload: any } }> = {
      data: { payload: orderMetrics },
      event: EventTypes.info,
    };
    dispatch(messagingActions.sendInfo(payload as any));
  }
);

// Apply Loyalty Code
export const applyLoyaltyCode = createAsyncThunk(
  'cart/applyLoyaltyCode',
  async (
    { loyaltyCode }: { loyaltyCode: string },
    { dispatch, rejectWithValue, getState }
  ) => {
    const rootState = getState();

    let restaurantCode =
      rootState.restaurant.selectedRestaurantDetails.restaurantCode ||
      undefined;
    let currentSessionId = rootState.order.currentSessionId;

    if (!currentSessionId) {
      currentSessionId = extractOrderId(rootState);
      logger.log(
        `Set currentSession in order slice to ${currentSessionId} in applyLoyaltyCode when it's null`
      );
      dispatch(orderActions.setCurrentSession(currentSessionId));
    }

    if (!restaurantCode) {
      restaurantCode = rootState.messages.startFrame?.data.restaurant_code;
      if (!restaurantCode) {
        throw rejectWithValue('No Current restaurantCode selected!');
      }
    }

    const requestId = uuidv4();
    const payload: Partial<ILoyaltyTransmissionMessage> = {
      data: {
        check_id: '-1', // check ID of target check
        loyalty_code: loyaltyCode, // loyalty offer to apply
        session_id: currentSessionId,
        source: EventSources.prestoVoice,
        request_id: requestId,
        store_id: restaurantCode,
        seq_id: '1',
      },
    };

    dispatch(sendLoyaltyMessage(payload as any));
    dispatch(populateLoyaltyRequests({ requestId }));
    dispatch(setCurrentSession(currentSessionId)); // Set currentSessionId in cart slice to be the same with currentSessionId in order slice
    dispatch(setLoyaltyCouponItem(undefined));
    dispatch(setLoyaltyCode(loyaltyCode));
    dispatch(setLoyaltyApplyStatus(LOYALTY_APPLY_STATUS.PROCESSING));
  }
);

const cartSlice = createSlice({
  name: 'cart',
  initialState: initialCartState,
  reducers: {
    populateLoyaltyRequests: (state, { payload: { requestId } }) => {
      state.requests[requestId] = {
        id: requestId,
        status: '',
        transactionId: '',
        loyaltyResponse: null,
        couponResponse: null,
      };
    },
    setCurrentSession: (state, action: PayloadAction<string>) => {
      state.currentSessionId = action.payload;
    },
    addItemToCart: (state, action: PayloadAction<CartItem>) => {
      state.cartItems[action.payload.cartItemId] = action.payload;
      state.cartItemsQuantity[action.payload.cartItemId] = 1;
      state.sequenceId++;
    },
    updateCartItem: (
      state,
      {
        payload: { cartItem, ignoreHypothesis },
      }: PayloadAction<{ cartItem: CartItem; ignoreHypothesis: boolean }>
    ) => {
      state.cartItems[cartItem.cartItemId] = cartItem;
    },
    selectModifier: (
      state,
      {
        payload: {
          cartItemId,
          menuItemId,
          modGroupId,
          selected,
          modCode,
          modSymbolMapping,
          pathToItem,
          rootModGroupId,
          posSettings,
        },
      }: PayloadAction<{
        cartItemId: number;
        menuItemId: string;
        modGroupId: string;
        selected: boolean;
        modCode: string;
        modSymbolMapping: ModSymbolCodeNameMappingType;
        pathToItem?: string;
        rootModGroupId?: string;
        posSettings: GenericMap<PosProperties>;
      }>
    ) => {
      const { cartItems, modality } = state;
      selectModifierInCartItem(
        cartItems[cartItemId],
        menuItemId,
        modGroupId,
        selected,
        modCode,
        modSymbolMapping,
        posSettings,
        modality,
        pathToItem,
        rootModGroupId
      );
    },
    cloneCartItem: (state, { payload }: PayloadAction<string>) => {
      state.sequenceId += 1;
      const cartItems = {
        ...state.cartItems,
        [state.sequenceId]: {
          ...state.cartItems[payload],
          cartItemId: state.sequenceId,
        },
      };
      state.cartItems = { ...cartItems };
      state.cartItemsQuantity[state.sequenceId] =
        state.cartItemsQuantity[payload];
      state.sequenceId += 1;
    },
    deleteCartItem: (state, { payload }: PayloadAction<string>) => {
      delete state.cartItems[payload];
      delete state.cartItemsQuantity[payload];
    },
    clearCart: () => {
      return Object.assign({}, initialCartState);
    },
    setLoyaltyApplyStatus: (
      state,
      { payload }: PayloadAction<LOYALTY_APPLY_STATUS | undefined>
    ) => {
      state.loyaltyApplyStatus = payload;
    },
    updateQuantity: (
      state,
      { payload }: PayloadAction<{ cartItemId: string; quantity: number }>
    ) => {
      if (payload.quantity > 0) {
        state.cartItemsQuantity[payload.cartItemId] = payload.quantity;
      } else {
        delete state.cartItems[payload.cartItemId];
        delete state.cartItemsQuantity[payload.quantity];
      }
    },
    setLoyaltyCode: (state, { payload }: PayloadAction<string>) => {
      state.loyaltyCode = payload;
    },
    setLoyaltyCouponItem: (
      state,
      { payload }: PayloadAction<LoyaltyCouponItem | undefined>
    ) => {
      state.loyaltyCouponItem = payload;
    },
    addCouponToCart: (state, { payload }: PayloadAction<string>) => {
      const id = uuidv4();
      state.coupons[id] = {
        id,
        code: payload,
      };
    },
    deleteCoupon: (state, { payload }: PayloadAction<string>) => {
      delete state.coupons[payload];
    },
    addHypothesisCartItem: (
      state,
      { payload }: PayloadAction<IEntityCartItem>
    ) => {
      state.hypothesisCartItems[payload.cartItemId] = payload;
      state.cartItemsQuantity[payload.cartItemId] = payload.quantity;
      state.sequenceId++;
    },
    deleteHypothesisItem: (state, { payload }: PayloadAction<string>) => {
      delete state.hypothesisCartItems[payload];
      delete state.cartItemsQuantity[payload];
    },
    setSequenceId: (state, { payload }: PayloadAction<number>) => {
      state.sequenceId = payload;
    },
    setPosCartItems: (
      state,
      { payload }: PayloadAction<ISendItemProperties[]>
    ) => {
      state.posCartItems = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(selectRestaurant.fulfilled, () => {
      return Object.assign({}, initialCartState);
    });
    builder.addCase(
      messagingActions.messageReceived,
      (state, action: PayloadAction<IMessageReceived>) => {
        const { messages } = action.payload;
        messages.forEach((message) => {
          if (message.session_id === state.currentSessionId) {
            if (message.event === EventTypes.coupon) {
              const { data } = message as ICheckCouponTransmissionMessage;
              const { request_id: requestId = '' } = data || {};

              if (state.requests[requestId]) {
                state.requests[requestId].couponResponse = message;
                processCouponResponses(state, requestId);
              }
            } else if (message.event === EventTypes.loyaltyStatus) {
              const { data: { request_id: requestId = '' } = {} } =
                message as IStatusTransmissionMessage;

              if (state.requests[requestId]) {
                state.requests[requestId].loyaltyResponse = message;
                processLoyaltyResponses(state, requestId);
              }
            }
          }
        });
      }
    );
    builder.addMatcher(
      isAnyOf(
        selectRestaurant.fulfilled,
        selectStage.fulfilled,
        selectMenuVersion.fulfilled
      ),
      (state, action) => {
        const newState = Object.assign({}, initialCartState);
        state = newState;
        return state;
      }
    );
  },
});

export const cartActions = cartSlice.actions;
export const {
  addItemToCart,
  updateCartItem,
  selectModifier,
  cloneCartItem,
  deleteCartItem,
  clearCart,
  updateQuantity,
  populateLoyaltyRequests,
  setCurrentSession,
  setLoyaltyApplyStatus,
  setLoyaltyCode,
  setLoyaltyCouponItem,
  addCouponToCart,
  deleteCoupon,
  addHypothesisCartItem,
  deleteHypothesisItem,
  setSequenceId,
  setPosCartItems,
} = cartSlice.actions;
export default cartSlice.reducer;
